//1.导入VueRouter
import Vue from 'vue'
import VueRouter from 'vue-router'
//2.使用路由
Vue.use(VueRouter)
//3.创建VueRouter的实例
const router = new VueRouter({
  //tips:不想要 #（锚点）就添加下面代码
  mode: 'history',
  //4.配置路由的path和组件
  routes: [
    {
      path: '/',
      component: () => import('@/views/home/index.vue'),
      redirect: 'home',
      children: [
        {
          path: 'home',
          component: () => import('@/views/home/home'),
          name: 'home'
        },
        {
          path: 'introduce',
          component: () => import('@/views/home/introduce'),
          name: 'introduce'
        },
        {
          path: 'form',
          component: () => import('@/views/home/form'),
          name: 'form'
        },
        {
          path: 'news',
          component: () => import('@/views/home/news'),
          name: 'news'
        },
        {
          path: 'entrants',
          component: () => import('@/views/home/entrants'),
          name: 'entrants'
        }
      ]
    },
    {
      path: '/signUp/introduce',
      name: '/signUp/introduce',
      component: () => import('@/views/signUp/introduce')
    },
    {
      path: '/login/index',
      name: '/login/index',
      component: () => import('@/views/login/index'),
      children: [
        {
          path: '/login',
          component: () => import('@/views/login/login'),
          name: 'login'
        },
        {
          path: '/register',
          component: () => import('@/views/login/register'),
          name: 'register'
        },
        {
          path: '/forget',
          component: () => import('@/views/login/forget'),
          name: 'forget'
        }
      ]
    },
    {
      path: '/newsDetail',
      name: '/newsDetail',
      component: () => import('@/views/newsDetail/index.vue')
    },
  ]
})
//5.导入路由实例
export default router
